import React, { useEffect, useState } from 'react';

import appStoreButton from '../../images/download-dltcash/app_store.svg';
import billonLogo from '../../images/download-dltcash/logo_billon.png';
import googlePlayButton from '../../images/download-dltcash/google_play.svg';
import { graphql } from 'gatsby';

const DownloadDltCashPage = (props) => {
    const [operatingSystem, setOperatingSystem] = useState(`unknown`);
    const iosLink = props.data.cms.downloadDltcashPage.iosLink ?? ``;
    const googlePlayLink = props.data.cms.downloadDltcashPage.googlePlayLink ?? ``;
    const timeoutLength = props.data.cms.downloadDltcashPage.timeoutLength ?? 3000;

    useEffect(() => {
        const userAgent = getMobileOperatingSystem();

        setOperatingSystem(userAgent);

        setTimeout(() => {
            if (userAgent === `iOS`) {
                if (iosLink) {
                    window.location.href = iosLink;
                }
            }

            if (userAgent === `Android`) {
                if (googlePlayLink) {
                    window.location.href = googlePlayLink;
                }
            }
        }, timeoutLength);
    });

    const getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            return `Android`;
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return `iOS`;
        }

        return `unknown`;
    };

    return (
        <div>
            <div className={`bg-black h-24 flex items-center justify-center`}>
                <img src={billonLogo} />
            </div>
            <div className={`flex flex-col items-center pt-12 pb-12 gap-6 px-4`}>
                <h3 className={`text-3xl font-semibold text-center`}>
                    Welcome to the world of true DLT cash on a distributed ledger
                </h3>

                <div className={`flex flex-col items-center`}>
                    <p className={`text-center`}>
                        Download our DLT CASH app
                    </p>
                </div>

                <div className={`flex gap-4`}>
                    {
                        operatingSystem === `iOS` || operatingSystem === `unknown` ? <a href={iosLink} id="iosLink">
                            <img src={appStoreButton} className={`w-48 h-14`} />
                        </a> : null
                    }

                    {
                        operatingSystem === `Android` || operatingSystem === `unknown` ? <a href={googlePlayLink} id="googlePlayLink" target="_top">
                            <img src={googlePlayButton} className={`w-48 h-14`} />
                        </a> : null
                    }

                </div>

            </div>


        </div>
    );
};

export default DownloadDltCashPage;

export const query = graphql`
query {
  cms {
    downloadDltcashPage {
      googlePlayLink
      iosLink
      timeoutLength
    }
  }
}
`;
